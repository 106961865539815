.playlist {
    border-radius: 30px;
    margin: 0 auto;
    overflow: hidden;
    width: 323px;

    .playlist__content {
        @include media-breakpoint-up(lg) {
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                height: 3px;
                width: 7px;
            }
            
            &::-webkit-scrollbar-track {
                height: 5px !important;
            }
            
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.3) !important;
                border-radius: 4px !important;
                height: 3px;
                visibility: hidden;
            }
        }
    }
}

.playlist__wrapper {
    margin: 0 auto;
    max-width: 323px;
}

.playlist__header {
    background-color: #393F6A;
    font-size: 15px;
    line-height: 1.55;
    padding: 14px 18px 12px;
    text-align: center;
}

.playlist__info {
    padding: 10px 10px 0;
    text-align: center;
}
.playlist__content {
    background-color: #fff;
    color: #161823;
    height: 492px;
    overflow: overlay;
    scrollbar-gutter: overlay;

    @include media-breakpoint-up(lg) {
        -webkit-overflow-scrolling: auto;
    }
}

.playlist__content:hover {
    @include media-breakpoint-up(lg) {
        position: relative;
        
        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }
}

.playlist__image {
    height: 323px;
    position: relative;

    img {
        height: 100%;
        object-fit: cover;
    }
}

.playlist__title {
    color: #080F45;
    font-family: "new-york-el";
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 9px;
}

.playlist__meta {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.playlist__item {
    align-items: center;
    display: flex;
    margin-bottom: 13px;
    text-align: left;
}

.playlist__itemImg {
    border-radius: 5px;
    overflow: hidden;
    margin-right: 1rem;
}

.playlist__itemContent {
    flex: 1;
    
    h2 {
        color: #080F45;
        font-family: "new-york-medium";
        font-size: 15.5px;
        margin-bottom: 5px;
    }

    p {
        color: #9C9FB5;
        font-size: 9px;
        margin: 0;
        text-transform: uppercase;
    }
}