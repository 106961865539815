.modal-content {
    border-radius: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .modal-header {
        border-bottom: 0;
        justify-content: center;
        padding-top: 26px;

        .btn-close {
            color: $primary;
            opacity: 1;
            position: absolute;
            right: 2rem;
        }
    }

    .modal-body {
        padding-bottom: 50px;
    }

    h3, h5 {
        color: $primary;
    }

    h3 {
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }

    h5 {
        font-family: $font-family-base;
        font-weight: 600;
    }
}