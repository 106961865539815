// Add extra utilities
.badge {
    border-radius: 50px;
    font-size: 16px;
    font-weight: normal;
    padding: 11px 20px 10px;
    white-space: normal;

    &.badge-muted {
        background: none;
        border: 1px solid $text-muted;
        color: $text-muted;
    }

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }
}

.bg-dark-transparency {
    background-color: rgba(35, 35, 35, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
}

.bg-grain {
    background-image: url('../img/grain-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-symbol {
    @extend %center-vertical;

    right: -80px;
    top: 0px;
    z-index: -1;
    animation: rotation 20s infinite linear;
}

.scroll-notice {
    bottom: 12px;
    color: #9C9FB5;
    font-size: 10px;
    line-height: 1.4;
    position: fixed;
    right: 12px;
    text-align: right;
    text-transform: uppercase;
    width: 72px;

    @include media-breakpoint-up(md) {
        right: 30px;
        bottom: 30px;
    }
}

.scroll-notice__label {
    margin-bottom: 12px;
}

.ff-base {
    font-family: $font-family-base;
}

.fs-xs {
    font-size: $font-size-xs;
}

.fs-10 {
    font-size: 10px;
}

.fs-14 {
    font-size: 14px;
}

.fs-25 {
    font-size: 20px;

    @include media-breakpoint-up(md) {
        font-size: 25px;
    }
}

.fs-48 {
    font-size: 27px;

    @include media-breakpoint-up(md) {
        font-size: 48px;
    }
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-800 {
    font-weight: 800;
}

.line-height-1 {
    line-height: 1;
}

.panel {
    background-color: $primary;
    border-radius: 16px;
    box-shadow: 0 4px 36px rgba(0, 0, 0, 0.44);
}

.price {
    font-family: $headings-font-family;
}

.price__regular {
    text-decoration: line-through;
}

.text-normal {
    text-transform: none;
}

.amp-middle {
    font-family: "new-york-el";
    font-size: 27px;
    font-weight: 500;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }