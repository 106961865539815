// Add type fonts
@font-face {
    font-family: "new-york-regular";
    src: url("../fonts/NewYork-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "new-york-medium";
    src: url("../fonts/NewYork-Medium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "new-york-bold";
    src: url("../fonts/NewYork-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "new-york-heavy";
    src: url("../fonts/NewYork-Heavy.otff") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "new-york-el";
    src: url("../fonts/NewYorkExtraLarge-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "new-york-el";
    src: url("../fonts/NewYorkExtraLarge-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "new-york-el";
    src: url("../fonts/NewYorkExtraLarge-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "new-york-el";
    src: url("../fonts/NewYorkExtraLarge-Heavy.otff") format("opentype");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "sf-pro-text";
    src: url("../fonts/SF-Pro-Text-Light.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "sf-pro-text";
    src: url("../fonts/SF-Pro-Text-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "sf-pro-text";
    src: url("../fonts/SF-Pro-Text-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "sf-pro-text";
    src: url("../fonts/SF-Pro-Text-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "sf-pro-text";
    src: url("../fonts/SF-Pro-Text-Heavy.otff") format("opentype");
    font-weight: 800;
    font-style: normal;
  }