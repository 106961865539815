// Add variables related to type styles
$font-family-base:     'sf-pro-text', sans-serif;
$headings-font-family: 'new-york-el', sans-serif;

$h1-font-size:          $font-size-base * 4; // 64px
$h2-font-size:          $font-size-base * 2.5; // 40px
$h3-font-size:          $font-size-base * 1.875; // 30px
$h4-font-size:          $font-size-base * 1.25; // 20px

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$font-size-base:        0.9375rem;
$font-size-xs:          11px;

$headings-font-weight:  400;
$headings-line-height:  1.2;

$line-height-base:      1.333;

$hr-height:             2px;
$hr-margin-y:           $spacer * 1.4375;
$hr-color:              $hhkit-blue-80;
$hr-height:             2px;
$hr-opacity:            1;

//$rfs-factor: 10;