label {
    font-size: 10px;
    text-transform: uppercase;
}

.form__fieldset {
    border-bottom: 1px dashed $text-muted;
    margin-bottom: 28px;
    padding-bottom: 39px;
    padding-top: 4px;
}

.form-group {
    label {
        color: #9C9FB5;
    }
}

.form-control {
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.form-select {
    padding-bottom: 9px;
    padding-top: 9px;
}

.digit-control {
    display: flex;
    flex-wrap: wrap;
    width: 114px;

    > * {
        color: #fff;
        font-size: 20px;
        height: 38px;
        text-align: center;
        width: 38px;
    }
}

.digit-control__btn {
    background-color: $hhkit-blue-93;
    border: 0;
    padding: 0;

    &:first-child {
        border-radius: 5px 0 0 5px;
    }

    &:last-child {
        border-radius: 0 5px 5px 0;
    }
}

.digit-control__input {
    background-color: $hhkit-blue-80;
    border: 0;
    border-radius: 0;

    &:focus {
        outline: none;
    }
}

.form-check-input {
    margin-top: 0;
}

.form-check-label {
    text-transform: none;
}