// Add extra utilities
footer {
    margin-top: 100px;
}

.footer__top {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    padding: 20px 0 30px;

    @include media-breakpoint-up(lg) {
        padding: 32px 0 42px;
    }
}

.footer__bottom {
    padding: 25px 0;

    @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 27px 0 70px;
    }
}

.footer__logo {
    margin-bottom: 31px;
    width: 88px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-right: 40px;
    }
}

.footer__main-menu {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.footer__main-menu-link {
    display: block;
    font-family: $headings-font-family;
    font-size: $h4-font-size;
    font-weight: 500;
    margin-bottom: 21px;
    margin-right: 24px;
    text-decoration: none;

    &:last-child {
        margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
        display: inline-block;
        font-size: $font-size-base;
        margin-bottom: 0;
    }
}

.footer__social-link {
    font-size: 12px;
    margin-left: 6px;

    &:first-child {
        margin-left: 0;
    }
}

.footer__small-menu, 
.footer__sitename {
    color: $text-muted;
    font-size: 10px;
}

.footer__small-menu-link {
    color: $text-muted;
    text-decoration: none;
}

.footer__sitename {
    margin-left: 14px;
}