// Add extra utilities
header {
    background-color: rgba(0, 0, 0, 0.21);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    left: 0;
    padding: 19px 16px 22px;
    position: absolute;
    top: 0;
    transition: all ease 0.2s;
    width: 100%;

    .btn-outline-light {
        border-radius: 8px;
        font-family: $font-family-base;
        font-size: 13.64px;
        font-weight: 500;
        letter-spacing: -0.25px;
        padding: 4px 8px 3px;

        i {
            font-size: 12px;
            margin-left: 5px;
            position: relative;
            top: 1px;
        }

        &:focus {
            box-shadow: none;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 19px 0 18px;
    }

    &.header--expanded {
        @include media-breakpoint-up(lg) {
            padding: 19px 0 36px;
        }
    }

    &.active {
        background-color: $primary;

        .header__menu-toggle {
            &:before {
                transform: rotate(-45deg);
                top: 4px;
            }

            &:after {
                transform: rotate(45deg);
                top: 4px;
            }

            span {
                display: none;
            }
        }

        .header__mobile-menu {
            top: 72px;
        }
    }
}

.header__logo {
    @include media-breakpoint-down(lg) {
        width: 110px;
    }
}

.header__main-menu {
    border-bottom: 1px solid $hhkit-blue-80;
    padding: 28px 15px 9px;

    @include media-breakpoint-up(lg) {
        border-bottom: 0;
        padding: 0;
    }
}

.header__main-menu-list {
    @include list-unstyled;

    display: inline-block;
    font-family: $headings-font-family;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
    }
}

.header__main-menu-item {
    margin: 0 22px 20px;
    position: relative;

    @include media-breakpoint-up(lg) {
        margin: 0 23px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.header__main-menu-item--dropdown {
    > .header__main-menu-link {
        &:after {
            @extend %icomoon;

            content: "\e900";
            font-size: 8px;
            margin-left: 6px;
            position: relative;
            top: -2px;
        }
    }

    &:hover {
        .header__sub-menu-list {
            display: block;
        }
    }
}

.header__main-menu-link {
    color: #fff;
    font-weight: 500;
    text-decoration: none;

    span.text-muted {
        font-family: $font-family-base;
        font-size: 12px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 20px;
    }
}

.header__sub-menu-list {
    @include list-unstyled;

    display: none;
    font-family: $font-family-base;
    margin: 0;
    padding-top: 9px;
    position: absolute;
    top: 100%;
    width: 440px;

    .header__main-menu-item {
        display: block;
        float: left;
    }

    .header__main-menu-link {
        font-size: 13px;
    }

    @include media-breakpoint-up(lg) {
        .header__main-menu-item {
            margin: 0 11px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.header__menu-toggle {
    display: inline-block;
    font-size: 9px;
    height: 16px;
    position: relative;
    text-decoration: none;
    top: 6px;
    width: 26px;

    &:after, 
    &:before {
        background-color: #fff;
        border-radius: 10px;
        content: " ";
        display: block;
        height: 2px;
        position: absolute;
        transition: all ease 0.2s;
        width: 26px;
    }

    span {
        background-color: #fff;
        border-radius: 10px;
        display: block;
        height: 2px;
        width: 26px;
    }

    &:before {
        top: 7px;
    }

    &:after {
        top: 14px;
    }
}

.header__mobile-menu {
    background-color: $primary;
    border-top: 1px solid $hhkit-blue-80;
    display: block;
    height: calc(100vh - 45px);
    left: 0;
    overflow: auto;
    position: absolute;
    top: -1000px;
    transition: all ease 0.2s;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        display: none!important;
    }
}



.header__social {
    padding: 31px 37px;
    text-align: center;
}