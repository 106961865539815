// Add extra utilities
.btn {
    i {
        font-size: 8px;
        margin-left: 7px;
    }
}

.btn-icon-md {
    i {
        font-size: 18px;
        position: relative;
        top: 3px;
    }
}

.btn-icon-lg {
    i {
        font-size: 20px;
        margin-left: -28px;
        margin-top: 2px;
        position: absolute;
    }
}

.btn-light {
    color: #080F45;
}