%bg-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

%fontawesome {
  display: inline-block;
  font-family: FontAwesome;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%center-item {
    position: absolute;
    top:50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

%center-vertical {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

%center-horizontal {
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

%overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

%icomoon {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%gradient-overlay {
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
  width: 100%;
}

%bold-titles {
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 0;
}

%bold-title-bg {
  color: #000;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #000;
}

%bold-title {
  color: #fff;
  left: 0;
  position: absolute;
  top: 0;
}

@mixin diagonal($width, $height, $style){
  border-top:$height solid white;
  border-right:$width $style transparent;
  content:"";
  position: absolute;
  top:0;
  right:-$width;
}
