body {
    background-image: url('../img/broadcast-tower.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 810px auto;
    padding-top: 72px;

    &.menu-open {
        overflow: hidden;
    }
}

main {
    overflow: hidden;
    padding: 50px 0;
}
