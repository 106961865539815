// Add variables related to color palette 
$hhkit-blue-40: #9C9FB5;
$hhkit-blue-60: #6B6F8F;
$hhkit-blue-80: #393F6A;
$hhkit-blue-93: #171E50;
$hhkit-blue:    #080F45;

$primary:           $hhkit-blue;

$body-bg:           $hhkit-blue;
$body-color:       #fff;

$headings-color:   #fff;

$link-color:       #fff;
$link-hover-color: #fff;

$text-muted: $hhkit-blue-40;

// scss-docs-start theme-colors-map

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) ;

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");