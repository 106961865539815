// Add extra utilities
.Zebra_DatePicker {
    margin: 21px 0 0 -21px;

    .dp_actions {
        .dp_caption {
            font-weight: 500;
        }

        .dp_today, .dp_clear, .dp_view_toggler:not(.dp_calendar) {
            display: none!important;
        }

        .dp_hover {
            background-color: rgba($primary, 0.25);
        }
    }

    .dp_icon {
        background-color: $light;

        &.dp_view_toggler.dp_calendar,
        &.dp_confirm {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
        }

        &.dp_view_toggler.dp_calendar {
            background-image: url('../img/calendar-black.svg');
        }

        &.dp_confirm {
            background-image: url('../img/check.svg');
        }
    }

    .dp_daypicker th {
        color: #737373;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    .dp_header {
        margin-bottom: 3px;
    }

    .dp_body {
        th, td {
            padding: 11px 5px;
        }
    }

    .dp_footer {
        margin-bottom: 3px;
        margin-top: 0;
    }
}

button.Zebra_DatePicker_Icon {
    bottom: 0;
    left: 0;
    opacity: 0;
    right: auto !important;
    top:auto !important;
}

.form-control__date {
    border: 1px solid $text-muted;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px;
    text-align: center;
}

.Zebra_DatePicker_Icon_Wrapper {
    width: 100%!important;
}